import ErrorStackParser from 'error-stack-parser';

import { getEnv } from '../env'
import sendBeacon from '../send-beacon';
import hasParam from '../has-param';
import { log } from '../log';

import {
  SentryStaticException,
  SentryDefaultConfig,
  SentryFullConfig,
  SentryStackFrame,
  SentryConfig,
  SentryBreadcrumb,
  SentryAnyBreadcrumb,
  SentryExceptionData,
  SentryException,
  SentryTags,
  SentryScope,
} from './sentry.types';

const env = getEnv();
const sentryVersion = '7';
let config: SentryFullConfig | undefined;

export const Severity = {
  Info: 'info',
};

const staticRequestData: SentryStaticException = {
  platform: 'javascript',
  sdk: {
    name: 'sentry.javascript.browser',
    packages: [
      {
        name: 'future:sentry/minimal',
        version: '0.0.1',
      },
    ],
    version: '5.20.1',
    integrations: ['InboundFilters', 'FunctionToString', 'TryCatch', 'Breadcrumbs', 'UserAgent'],
  },
  request: {
    url: env.location.href,
    headers: {
      'User-Agent': env.navigator.userAgent,
    },
  },
  extra: {
    arguments: [],
  },
};

const getURLFromDSN = (dsn: string): string => {
  // Configured in the form: https://899032cf20894ca3ac193916e76d1ff5@o362810.ingest.sentry.io/5227073
  // Used in the form: https://o362810.ingest.sentry.io/api/5227073/store/?sentry_key=899032cf20894ca3ac193916e76d1ff5&sentry_version=7
  const parsed = new URL(dsn);
  return `${parsed.origin}/api${parsed.pathname}/store/?sentry_key=${parsed.username}&sentry_version=${sentryVersion}`;
};

const getRandomInt = (max: number): number => Math.floor(Math.random() * Math.floor(max));
const generateIdChar = (): string => getRandomInt(16).toString(16);
const generateId = (): string => Array(32).fill(0).map(generateIdChar).join('');

const currentTimestamp = (): number => {
  if ('performance' in env) {
    return (env.performance.timeOrigin + env.performance.now()) / 1000;
  }
  return Date.now() / 1000;
};

const deniedPageURL = (): boolean =>
  Boolean(config?.denyUrls.find(urlRegex => window.location.hostname.match(urlRegex)));

const allowedScriptURL = (frame: SentryStackFrame): boolean =>
  Boolean(config?.allowUrls?.find(urlRegex => frame.filename.match(urlRegex)));

const sendError = (errorData: SentryException): void => {
  if (config === undefined) {
    log.warn(`Error ignored because Sentry is not yet configured`);
    return;
  }
  const sent = sendBeacon(config.apiURL, errorData);
  if (!sent) {
    log.error('Sentry send failed');
  }
};

const defaultConfig: SentryDefaultConfig = {
  environment: 'production',
  ignoreErrors: [],
  denyUrls: [],
  debug: false,
};

const SAMPLE_RATE = 0.01;
let sentryEnabled = false;
const setupEnabled = (): void => {
  const randomlySampled: boolean = Math.random() < SAMPLE_RATE;
  const forced: boolean = hasParam('force_sentry');
  sentryEnabled = randomlySampled || forced;
};

export const init = (initConfig: SentryConfig): void => {
  if (!('dsn' in initConfig)) {
    throw new Error('You must provide a DSN to the Sentry init');
  }
  if (!('release' in initConfig)) {
    throw new Error('You must provide a release version to the Sentry init');
  }

  config = {
    ...defaultConfig,
    ...initConfig,
    apiURL: getURLFromDSN(initConfig.dsn),
  };

  setupEnabled();
};

const tags: SentryTags = {};
const scope: SentryScope = {
  setTag: (tagName: string, tagValue: string) => {
    tags[tagName] = tagValue;
  },
};
export const configureScope = (callback: (scope: SentryScope) => void): void => {
  callback(scope);
};

const breadcrumbs: SentryAnyBreadcrumb[] = [];
export const addBreadcrumb = (crumb: SentryBreadcrumb): void => {
  breadcrumbs.push({
    timestamp: currentTimestamp(),
    ...crumb,
  });
};

const parsedStackFramesToSentryStackFrames = (frame): SentryStackFrame => ({
  colno: frame.columnNumber,
  filename: frame.fileName,
  function: frame.functionName === 'undefined' ? '?' : frame.functionName,
  lineno: frame.lineNumber,
  in_app: true,
});

const getExceptionData = (error: string | Error): SentryExceptionData => {
  let message: string;
  let frames: SentryStackFrame[];

  if (typeof error === 'string') {
    message = error;
    frames = [];
  } else {
    message = error.message;
    const stackFrames = ErrorStackParser.parse(error);
    frames = stackFrames.map(parsedStackFramesToSentryStackFrames).reverse();
  }

  return {
    type: 'Error',
    value: message,
    stacktrace: { frames },
    mechanism: {
      handled: true,
      type: 'generic',
    },
  };
};

export const captureException = (error: string | Error): void => {
  if (config === undefined) {
    log.warn(`Error ignored because Sentry is not yet configured`);
    return;
  }

  if (!sentryEnabled) {
    return;
  }

  if (deniedPageURL()) {
    log.warn(`Error ignored because the page URL is denied`);
    return;
  }

  // Return if error ignored
  const message = typeof error === 'string' ? error : error.message;
  if (config.ignoreErrors.find(ignore => message.includes(ignore))) {
    log.warn(`Error ignored because it appears in ignore error list`);
    return;
  }

  // Return if URL is denied
  const exceptionData = getExceptionData(error);

  // Return if URL is not allowed
  if (
    config.allowUrls &&
    exceptionData.stacktrace.frames.length > 0 &&
    !exceptionData.stacktrace.frames.find(allowedScriptURL)
  ) {
    log.warn(`Error ignored because no scripts in the stack trace have an allowed URL`);
    return;
  }

  const preparedData: SentryException = {
    level: 'error',
    ...staticRequestData,
    event_id: generateId(),
    timestamp: currentTimestamp(),
    environment: config.environment,
    release: config.release,
    tags,
    breadcrumbs,
    exception: {
      values: [exceptionData],
    },
  };

  // Do beforeSend map
  if (config.beforeSend) {
    const transformedData = config.beforeSend(preparedData);
    if (!transformedData) {
      log.warn(`Error ignored because beforeSend returned null`);
      return;
    }
    sendError(transformedData);
  } else {
    sendError(preparedData);
  }
};
