import stringyNumber from '../generic/stringy-number';
import { z } from 'zod';

export const settingsSchema = z
  .object({
    refreshTime: z.number().optional(),
    adDensity: z
      .object({
        avoidanceDistance: stringyNumber,
      })
      .optional(),
    roadblock: z
      .object({
        incrementalCap: z.number(),
      })
      .optional(),
  })
  .default({});
