import { ThirdPartyAPIMachineConfigs, ThirdParty } from '@repo/shared-types';
import loadScript from 'third-party-apis/utils/load-script';
import { PartialPick } from '@repo/utils';

const config: PartialPick<ThirdPartyAPIMachineConfigs[ThirdParty.EUID], 'thirdParty'> = {
  thirdParty: ThirdParty.EUID,
  methods: {
    getScriptLocation: () => `https://prod.euid.eu/static/js/euid-sdk-1.0.0.js`,
    loadScript: scriptLocation =>
      loadScript(scriptLocation, 'euid-script', { async: true, defer: true }),
  },
};
export default config;
