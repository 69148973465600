import getEnv from './env';
import { log } from './log';

const env = getEnv();

export default <T extends Element>(selector: string): Array<T> => {
  try {
    const results: Array<T> = Array.from(env.document.querySelectorAll(selector));
    return results;
  } catch (error) {
    log.error('Error while selecting DOM elements', error);
    return [];
  }
};
