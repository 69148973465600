import { Ad, DataObject } from '@repo/shared-types';
import { AdElementGroup } from '@repo/shared-types/src/types/ad-framework/ad/index.types';
import { createElement } from '@repo/utils';

const createOutOfPageMarkup = (ad: DataObject<Ad>): AdElementGroup => {
  const element = createElement('div', {
    id: ad.getProperty('id'),
  });
  const outerContainer = createElement(
    'div',
    {
      style: {
        display: 'none',
      },
    },
    element,
  );
  return {
    element,
    outerContainer,
  };
};

export default createOutOfPageMarkup;
