import { init as adManagerInit } from 'ad-framework/ad-manager/index';
import metrics from 'metrics';
import userSync from 'user-sync';
import {
  sendABTestToFreyr,
  pushToGoogleAnalytics,
  containsExperiment,
  containsVariant,
  getVariant,
  sendUserIdEventToFreyr,
  waitForJwPLayer,
  log,
} from '@repo/utils';
import assign from './proxy/assign';
import enqueueActions from './proxy/enqueueActions';
import { ActionArgs, BordeauxMachineContext } from '@repo/shared-types';

export const handleHybridAbTestsIfAny = ({ context }: ActionArgs): void => {
  pushToGoogleAnalytics(context.config.placement);

  const { placement } = context.config;
  if (placement?.abTest) {
    placement.abTest.forEach(gaBean => {
      sendABTestToFreyr(gaBean);
    });
  }
};

export const setupCustomActivations = assign({
  unrefreshableLineItems: ({ context }) => {
    const adRefreshDisabled = context.config.features.customActivations.AD_REFRESH_DISABLED;
    if (adRefreshDisabled.LINE_ITEM) {
      return adRefreshDisabled.LINE_ITEM;
    }
    return context.unrefreshableLineItems;
  },
  unrefreshableOrders: ({ context }) => {
    const adRefreshDisabled = context.config.features.customActivations.AD_REFRESH_DISABLED;
    if (adRefreshDisabled.ORDER) {
      return adRefreshDisabled.ORDER;
    }
    return context.unrefreshableOrders;
  },
  unrefreshableAdvertisers: ({ context }) => {
    const adRefreshDisabled = context.config.features.customActivations.AD_REFRESH_DISABLED;
    if (adRefreshDisabled.ADVERTISER) {
      return adRefreshDisabled.ADVERTISER;
    }
    return context.unrefreshableAdvertisers;
  },
});

export const decideRefreshTime = enqueueActions(() => {
  /** Noop - no special refresh time currently in use */
});

export const setupUserSync = ({ context }: ActionArgs): void => {
  userSync(context);
};

export const setupAdManager = ({ context }: ActionArgs): void => {
  adManagerInit(context);
};

export const handleError = (): void => {
  metrics.recordFrameworkCrash();
};

export const sendUserIdsToFreyr = ({ context }: ActionArgs): void => {
  const hybridId = [{ name: 'hybridId', id: context.hybridId }];
  const emailHash = context.queryParameters.lrh
    ? [{ name: 'email_sha256', id: context.queryParameters.lrh }]
    : [];

  sendUserIdEventToFreyr([...hybridId, ...emailHash]);
};

const TEST_KEYS = ['hybridTestID', 'hybridQATestID'];
type VariantValueMap = {
  [variantId: string]: { mobile: number; tablet: number; desktop: number };
};
const variantValues: VariantValueMap = {
  '25|95': { mobile: 3000, tablet: 3000, desktop: 3000 },
  '25|96': { mobile: 3500, tablet: 3500, desktop: 3500 },
  '25|97': { mobile: 4000, tablet: 4000, desktop: 4000 },
  '25|98': { mobile: 4500, tablet: 4500, desktop: 4500 },
  '25|99': { mobile: 5000, tablet: 5000, desktop: 5000 },
  '25|100': { mobile: 5500, tablet: 5500, desktop: 5500 },
  '25|101': { mobile: 6000, tablet: 6000, desktop: 6000 },
  '25|102': { mobile: 6500, tablet: 6500, desktop: 6500 },
  '25|103': { mobile: 7000, tablet: 7000, desktop: 7000 },
};

export const storeHybridTestSessions = ({ context }: ActionArgs): void => {
  Object.entries(context.config.targeting)
    .filter(([key]) => TEST_KEYS.includes(key))
    .forEach(([, value]) => {
      sessionStorage.setItem('force_abtest', typeof value === 'string' ? value : value.join(','));
    });
};

export const decideTestAuctionTimeouts = assign({
  auctionTimeouts: ({ context }): BordeauxMachineContext['auctionTimeouts'] => {
    const variantEntry = Object.entries(context.sommelierResponse.targeting || {})
      .reverse()
      .find(
        ([key, value]) =>
          TEST_KEYS.includes(key) &&
          ((typeof value === 'string' && variantValues[value]) ||
            (Array.isArray(value) && value.some(v => v in variantValues))),
      );
    if (!variantEntry) return context.auctionTimeouts;

    const variantValue = variantEntry[1];
    return typeof variantValue === 'string'
      ? variantValues[variantValue]
      : variantValues[variantValue.find(v => v in variantValues) as string];
  },
});

export const decidePubxAbTest = assign({
  thirdPartyApiConfigOverrides: ({
    context,
  }): BordeauxMachineContext['thirdPartyApiConfigOverrides'] =>
    containsExperiment(context.config, '41')
      ? {
          ...context.thirdPartyApiConfigOverrides,
          pubx: {
            enabled: containsVariant(context.config, '41', '140'),
          },
        }
      : context.thirdPartyApiConfigOverrides,
});

export const decideIdsActivationTest = assign({
  thirdPartyApiConfigOverrides: ({
    context,
  }): BordeauxMachineContext['thirdPartyApiConfigOverrides'] => {
    if (containsExperiment(context.config, '43')) {
      const variant = getVariant(context.config, '43');
      switch (variant) {
        case '142':
          return {
            ...context.thirdPartyApiConfigOverrides,
            euid: {
              enabled: false,
            },
            liveRamp: {
              ...context.thirdPartyApiConfig.liveRamp,
              enabled: false,
            },
            liveIntent: {
              enabled: false,
            },
            uid2: {
              enabled: false,
            },
          };
        case '143':
          return {
            ...context.thirdPartyApiConfigOverrides,
            euid: {
              enabled: true,
            },
            liveRamp: {
              ...context.thirdPartyApiConfig.liveRamp,
              enabled: false,
            },
            liveIntent: {
              enabled: false,
            },
            uid2: {
              enabled: false,
            },
          };
        case '144':
          return {
            ...context.thirdPartyApiConfigOverrides,
            euid: {
              enabled: false,
            },
            liveRamp: {
              ...context.thirdPartyApiConfig.liveRamp,
              enabled: false,
            },
            liveIntent: {
              enabled: false,
            },
            uid2: {
              enabled: true,
            },
          };
        case '145':
          return {
            ...context.thirdPartyApiConfigOverrides,
            euid: {
              enabled: false,
            },
            liveRamp: {
              ...context.thirdPartyApiConfig.liveRamp,
              enabled: false,
            },
            liveIntent: {
              enabled: true,
            },
            uid2: {
              enabled: false,
            },
          };
        case '146':
          return {
            ...context.thirdPartyApiConfigOverrides,
            euid: {
              enabled: false,
            },
            liveRamp: {
              ...context.thirdPartyApiConfig.liveRamp,
              enabled: true,
            },
            liveIntent: {
              enabled: false,
            },
            uid2: {
              enabled: false,
            },
          };
        case '147':
          return {
            ...context.thirdPartyApiConfigOverrides,
            euid: {
              enabled: true,
            },
            liveRamp: {
              ...context.thirdPartyApiConfig.liveRamp,
              enabled: true,
            },
            liveIntent: {
              enabled: true,
            },
            uid2: {
              enabled: true,
            },
          };
        default:
          return context.thirdPartyApiConfigOverrides;
      }
    } else {
      return context.thirdPartyApiConfigOverrides;
    }
  },
});

export const customVideoBehaviourAction = ({
  context: {
    ads,
    isRoadblock,
    config,
    pageParameters: { site },
  },
}: ActionArgs): void => {
  const allowedSites = ['marieclairecom-progressive', 'whowhatwear-progressive'];
  if (!allowedSites.includes(site)) {
    return;
  }
  if (!isRoadblock) return;

  const {
    features: { customActivations },
  } = config;

  waitForJwPLayer()
    .then(jw => {
      const gptOutputs = ads
        .getValues()
        .map(ad => ad.getProperty('gptOutput'))
        .filter(gptOutput => !!gptOutput);

      const isLineItemVideoStickyDisabled = gptOutputs
        .map(gptOutput => gptOutput.lineItem)
        .some(lineItem =>
          customActivations.VIDEO_STICKY_AUTOPLAY_DISABLED.LINE_ITEM?.includes(lineItem),
        );

      const isAdvertiserVideoStickyDisabled = gptOutputs
        .map(gptOutput => gptOutput.advertiser)
        .some(advertiser =>
          customActivations.VIDEO_STICKY_AUTOPLAY_DISABLED.ADVERTISER?.includes(advertiser),
        );

      const isOrderVideoStickyDisabled = gptOutputs
        .map(gptOutput => gptOutput.campaign)
        .some(order => customActivations.VIDEO_STICKY_AUTOPLAY_DISABLED.ORDER?.includes(order));

      const disableStatus =
        isLineItemVideoStickyDisabled ||
        isAdvertiserVideoStickyDisabled ||
        isOrderVideoStickyDisabled;

      if (disableStatus) {
        jw().remove();
      }
    })
    .catch(err => {
      log.warn(err);
    });
};
