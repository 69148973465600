import {
  GPTOutput,
  Ad,
  AdUnitMode,
  Slot,
  DataObject,
  BordeauxMachineContext,
} from '@repo/shared-types';

const isRAMPGumGum = (adGPTOutput: GPTOutput): boolean => {
  const lineItems = [244592569];

  const targeting = adGPTOutput.gptSlot?.getTargeting('_bn');
  if (targeting === undefined) {
    return false;
  }

  if (
    adGPTOutput.lineItem !== undefined &&
    lineItems.includes(adGPTOutput.lineItem) &&
    targeting.includes('Gumgum')
  ) {
    return true;
  }

  return false;
};

export const adCanRefresh =
  (
    context: Pick<
      BordeauxMachineContext,
      | 'unrefreshableNames'
      | 'unrefreshableStatuses'
      | 'unrefreshableModes'
      | 'unrefreshableCategories'
      | 'unrefreshableOrders'
      | 'unrefreshableLineItems'
      | 'unrefreshableAdvertisers'
      | 'anchoredRefreshDisabled'
    >,
  ) =>
  (ad: DataObject<Ad>) => {
    if (!ad.getProperty('refresh')) {
      return false;
    }
    if (ad.getProperty('refreshDisabled')) {
      return false;
    }

    if (context.anchoredRefreshDisabled && ad.getProperty('mode') === AdUnitMode.ANCHORED) {
      return false;
    }

    if (context.unrefreshableNames.includes(ad.getProperty('name'))) {
      return false;
    }
    if (context.unrefreshableStatuses.includes(ad.getProperty('status'))) {
      return false;
    }
    if (context.unrefreshableModes.includes(ad.getProperty('mode'))) {
      return false;
    }
    const category = ad.getProperty('category');
    if (category && context.unrefreshableCategories.includes(category)) {
      return false;
    }

    const width = ad.getProperty('width');
    const height = ad.getProperty('height');
    const refreshDisabledSizes = ad.getProperty('refreshDisabledSizes');
    const refreshDisabledBySize = refreshDisabledSizes.some(
      size => size[0] === width && size[1] === height,
    );
    if (refreshDisabledBySize) {
      return false;
    }

    const adGPTOutput = ad.getProperty('gptOutput');
    if (
      adGPTOutput === undefined ||
      adGPTOutput.campaign === undefined ||
      adGPTOutput.lineItem === undefined
    ) {
      return true;
    }

    if (context.unrefreshableOrders.includes(adGPTOutput.campaign)) {
      return false;
    }
    if (context.unrefreshableLineItems.includes(adGPTOutput.lineItem)) {
      return false;
    }
    if (context.unrefreshableAdvertisers.includes(adGPTOutput.advertiser)) {
      return false;
    }
    if (isRAMPGumGum(adGPTOutput)) {
      return false;
    }

    return true;
  };

export const tandemAdsPresent = (
  adsCanRefresh: Array<DataObject<Ad>>,
  slots: Array<DataObject<Slot>>,
): Array<DataObject<Ad>> =>
  adsCanRefresh.flatMap(ad => {
    const slotID = ad.getProperty('slotID');
    if (!slotID) return [ad];
    const slot = slots.find(slot => slot.getProperty('id') === slotID);
    if (!slot) return [ad];
    if (slot.getProperty('master')) return [];
    const companions = slots.filter(slot => slot.getProperty('masterID') === slotID);
    if (!companions.length) return [ad];
    const companionIds = companions.map(slot => slot.getProperty('id'));
    const companionAds = adsCanRefresh.filter(ad => {
      const otherSlotID = ad.getProperty('slotID');
      if (!otherSlotID) return false;
      return companionIds.includes(otherSlotID);
    });
    if (companionAds.length === companionIds.length) return [ad, ...companionAds];
    return [];
  });
