import { log } from '@repo/utils';
import { partition } from '@repo/utils';
import { ApiMachineEventService } from './events.service';
import { API_EVENTS_OUT, AddUnrefreshableNamesApiEvent } from '@repo/shared-types';

export default (service: ApiMachineEventService, unrefreshableAds: Array<string>): void => {
  if (!Array.isArray(unrefreshableAds)) {
    log.error(`Attempted to set unrefreshable ads with ${JSON.stringify(unrefreshableAds)}`);
    return;
  }
  const [invalidAds, validAds] = partition(unrefreshableAds, adName => typeof adName === 'string');

  invalidAds.forEach(ad => {
    log.error(`Attempted to set unrefreshable ad name with ${JSON.stringify(ad)}`);
  });

  service.sendEvent({
    type: API_EVENTS_OUT.ADD_UNREFRESHABLE_NAMES,
    data: validAds,
  } as AddUnrefreshableNamesApiEvent);
};
