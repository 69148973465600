/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import { hasUserConsentedVendorGDPR } from '@repo/utils';
import { TimeoutError } from '@repo/utils';
import { log } from '@repo/utils';
import { assign, fromPromise, sendParent, setup } from 'xstate';
import { PartialPick } from '@repo/utils';
import {
  ThirdPartyAPIMachineContext,
  ThirdParty,
  THIRD_PARTY_API_ACTIONS,
  THIRD_PARTY_API_GUARDS,
  THIRD_PARTY_API_STATES,
  APISetupResult,
  ThirdPartyFailureEvent,
  ThirdPartyRequestEvent,
  ThirdPartySuccessEvent,
  ThirdPartyTimeoutEvent,
  ThirdPartyEvent,
} from '@repo/shared-types';
import { timeout, timeoutMessage } from './utils/timeout';

const timeoutScript = (thirdparty: ThirdParty): Promise<void> =>
  new Promise((_resolve, reject) => {
    setTimeout(() => {
      reject(new TimeoutError(`${thirdparty}: ${timeoutMessage} [${timeout}ms]`));
    }, timeout);
  });

const loadScript = fromPromise<void, { context: ThirdPartyAPIMachineContext }>(
  ({ input }): Promise<void> =>
    Promise.race([
      input.context.data.methods.loadScript &&
        (input.context.data.methods.loadScript as Function)(
          input.context.data.scriptLocation,
          input.context,
        ),
      timeoutScript(input.context.data.thirdParty),
    ]),
);

export const setupThirdPartyAPI = setup({
  types: {} as {
    context: ThirdPartyAPIMachineContext;
    input: {
      thirdPartyMethods: PartialPick<ThirdPartyAPIMachineContext['data'], 'thirdParty'>;
      bordeaux: ThirdPartyAPIMachineContext['bordeaux'];
    };
    output: APISetupResult;
  },
  guards: {
    [THIRD_PARTY_API_GUARDS.NOT_ENABLED]: ({
      context: {
        data: { config },
      },
    }) => !config.enabled,
    [THIRD_PARTY_API_GUARDS.NO_CONSENT]: ({ context: { consent } }) => !consent,
    [THIRD_PARTY_API_GUARDS.NO_SCRIPT]: ({
      context: {
        data: { methods, scriptLocation },
      },
    }) => !scriptLocation || !methods.loadScript,
  },
  actions: {
    [THIRD_PARTY_API_ACTIONS.GET_CONFIG]: assign({
      data: ({ context }) => ({
        ...context.data,
        config: context.data.methods.getConfig
          ? (context.data.methods.getConfig as Function)(context)
          : context.bordeaux.thirdPartyApiConfig[context.data.thirdParty],
      }),
    }),
    [THIRD_PARTY_API_ACTIONS.GET_CONSENT]: assign({
      consent: ({
        context: {
          bordeaux,
          data: { config },
        },
      }) =>
        !('consentVendor' in config) ||
        config.consentVendor === undefined ||
        hasUserConsentedVendorGDPR(bordeaux.gdprConsent, config.consentVendor),
    }),
    [THIRD_PARTY_API_ACTIONS.GET_SCRIPT_LOCATION]: assign({
      data: ({ context }) => ({
        ...context.data,
        scriptLocation: context.data.methods.getScriptLocation
          ? (context.data.methods.getScriptLocation as Function)(context)
          : context.data.scriptLocation,
      }),
    }),
    [THIRD_PARTY_API_ACTIONS.MARK_SUCCESS]: assign({
      success: true,
    }),
    [THIRD_PARTY_API_ACTIONS.SEND_REQUEST_EVENT]: sendParent(
      ({
        context: {
          data: { thirdParty },
        },
      }): ThirdPartyRequestEvent => ({
        type: ThirdPartyEvent.THIRD_PARTY_REQUEST,
        data: thirdParty,
      }),
    ),
    [THIRD_PARTY_API_ACTIONS.SEND_SUCCESS_EVENT]: sendParent(
      ({
        context: {
          data: { thirdParty },
        },
      }): ThirdPartySuccessEvent => ({
        type: ThirdPartyEvent.THIRD_PARTY_SUCCESS,
        data: thirdParty,
      }),
    ),
    [THIRD_PARTY_API_ACTIONS.SEND_FAILURE_EVENT]: sendParent(
      ({
        context: {
          data: { thirdParty },
        },
        event,
      }): ThirdPartyFailureEvent | ThirdPartyTimeoutEvent => ({
        type:
          event.data instanceof TimeoutError
            ? ThirdPartyEvent.THIRD_PARTY_TIMEOUT
            : ThirdPartyEvent.THIRD_PARTY_FAILURE,
        data: thirdParty,
      }),
    ),
  },
  actors: {
    loadScript,
  },
}).createMachine({
  initial: THIRD_PARTY_API_STATES.START,
  context: ({ input }) => ({
    data: {
      config: {},
      methods: {},
      ...input.thirdPartyMethods,
    } as ThirdPartyAPIMachineContext['data'],
    consent: false,
    scriptLocation: undefined,
    success: false,
    bordeaux: input.bordeaux,
  }),
  output: ({
    context: {
      data: { thirdParty, config, scriptLocation },
      consent,
      success,
    },
  }): APISetupResult => ({
    thirdParty,
    config,
    consent,
    scriptLocation,
    success,
  }),
  states: {
    [THIRD_PARTY_API_STATES.START]: {
      entry: [
        THIRD_PARTY_API_ACTIONS.GET_CONFIG,
        THIRD_PARTY_API_ACTIONS.GET_CONSENT,
        THIRD_PARTY_API_ACTIONS.GET_SCRIPT_LOCATION,
      ],
      always: [
        {
          guard: THIRD_PARTY_API_GUARDS.NOT_ENABLED,
          target: THIRD_PARTY_API_STATES.DONE,
        },
        {
          guard: THIRD_PARTY_API_GUARDS.NO_CONSENT,
          target: THIRD_PARTY_API_STATES.DONE,
        },
        {
          guard: THIRD_PARTY_API_GUARDS.NO_SCRIPT,
          actions: THIRD_PARTY_API_ACTIONS.MARK_SUCCESS,
          target: THIRD_PARTY_API_STATES.DONE,
        },
        {
          target: THIRD_PARTY_API_STATES.LOAD,
        },
      ],
    },
    [THIRD_PARTY_API_STATES.LOAD]: {
      entry: THIRD_PARTY_API_ACTIONS.SEND_REQUEST_EVENT,
      invoke: {
        src: 'loadScript',
        input: ({ context }) => ({ context }),
        onDone: {
          actions: [
            THIRD_PARTY_API_ACTIONS.SEND_SUCCESS_EVENT,
            THIRD_PARTY_API_ACTIONS.MARK_SUCCESS,
          ],
          target: THIRD_PARTY_API_STATES.DONE,
        },
        onError: {
          actions: [
            ({ event }) => log.warn(event.error),
            THIRD_PARTY_API_ACTIONS.SEND_FAILURE_EVENT,
          ],
          target: THIRD_PARTY_API_STATES.DONE,
        },
      },
    },
    [THIRD_PARTY_API_STATES.DONE]: {
      type: 'final',
    },
  },
});
