import { removeAllAdHighlights, removeAdHighlight } from 'ad-framework/highlight/ads';

export default (names?: Array<string>): void => {
  if (names === undefined) {
    removeAllAdHighlights();
    return;
  }

  names.forEach(name => {
    removeAdHighlight(name);
  });
};
