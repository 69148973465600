import { log } from '@repo/utils';
import { API_EVENTS_OUT } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';

export default (service: ApiMachineEventService, version = ''): void => {
  if (typeof version !== 'string') {
    log.error(`Argument passed to setAdToolVersion must be a string, ${typeof version} given`);
    return;
  }
  service.sendEvent({
    type: API_EVENTS_OUT.SET_ADTOOL_VERSION,
    data: version,
  });
};
