import { getEnv } from '@repo/utils';
import { getUserSyncURL } from './utils';
import { UserSyncElement, UserSyncConfig } from '@repo/shared-types';

const id = '9';
const sonobi: UserSyncConfig = {
  element: UserSyncElement.IMAGE,
  bidderName: 'sonobi',
  id,
  iabIdList: [104],
  script: {
    src: 'https://sync.go.sonobi.com/uc.js',
  },
  condition: () => {
    const env = getEnv();
    if (env.gdprUser) {
      return false;
    }
    return true;
  },
  url: `https://purch-sync.go.sonobi.com/us?${getUserSyncURL({ id, uid: '[UID]' })}`,
};
export default sonobi;
