import { log } from '@repo/utils';
import { DeviceOptions, API_EVENTS_OUT, AuctionTimeouts } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';

export default (service: ApiMachineEventService, auctionTimeouts: AuctionTimeouts): void => {
  if (typeof auctionTimeouts !== 'object') {
    log.error(
      `Argument passed to setAuctionTimeouts must be an object, ${typeof auctionTimeouts} given`,
    );
    return;
  }

  if (
    !(
      DeviceOptions.MOBILE in auctionTimeouts &&
      typeof auctionTimeouts.mobile === 'number' &&
      DeviceOptions.TABLET in auctionTimeouts &&
      typeof auctionTimeouts.tablet === 'number' &&
      DeviceOptions.DESKTOP in auctionTimeouts &&
      typeof auctionTimeouts.desktop === 'number'
    )
  ) {
    log.error(
      `Argument passed to setAuctionTimeouts must be an object, and must contain the keys "${DeviceOptions.MOBILE}", "${DeviceOptions.TABLET}" and "${DeviceOptions.DESKTOP}" with number values`,
    );
    return;
  }

  service.sendEvent({
    type: API_EVENTS_OUT.SET_AUCTION_TIMEOUTS,
    data: auctionTimeouts,
  });
};
