import { BordeauxMachineContext } from '@repo/shared-types';
import { FullAPISetupResults, THIRD_PARTY_API_STATES } from '@repo/shared-types';
import { fromPromise, waitFor } from 'xstate';

const waitForAllThirdParties = fromPromise<
  FullAPISetupResults,
  { thirdPartyMachines: BordeauxMachineContext['thirdPartyMachines'] }
>(
  async ({ input }): Promise<FullAPISetupResults> =>
    Object.fromEntries(
      await Promise.all(
        Object.entries(input.thirdPartyMachines).map(async ([thirdParty, machine]) => {
          const { context } = await waitFor(
            machine,
            ({ value }) => value === THIRD_PARTY_API_STATES.DONE,
          );
          return [
            thirdParty,
            {
              thirdParty: context.data.thirdParty,
              scriptLocation: context.data.scriptLocation,
              success: context.success,
              consent: context.consent,
              config: context.data.config,
            },
          ];
        }),
      ),
    ),
);
export default waitForAllThirdParties;
