import { ThirdPartyAPIMachineConfigs, ThirdParty } from '@repo/shared-types';
import loadScript from 'third-party-apis/utils/load-script';
import { getEnv } from '@repo/utils';
import { PartialPick } from '@repo/utils';

const config: PartialPick<ThirdPartyAPIMachineConfigs[ThirdParty.INDEX_EXCHANGE], 'thirdParty'> = {
  thirdParty: ThirdParty.INDEX_EXCHANGE,
  methods: {
    getScriptLocation: ({
      data: {
        config: { id },
      },
    }) => `https://js-sec.indexww.com/ht/p/184056-${id}.js`,
    loadScript: async scriptLocation => {
      const env = getEnv();
      env.googletag = env.googletag || ({ cmd: [] } as unknown as googletag.Googletag);
      env.googletag.cmd = env.googletag?.cmd || [];
      await loadScript(scriptLocation, 'index-exchange-script', { async: true });

      await new Promise<void>((resolve, reject) => {
        if (env.googletag) {
          let setupComplete = false;
          env.googletag.cmd.push((): void => {
            if (setupComplete) return;
            setupComplete = true;
            if (!('headertag' in env) || !env.headertag) {
              reject(Error(`Index Exchange headertag does not exist on the page.`));
            } else {
              resolve();
            }
          });
        }
      });
    },
  },
};
export default config;
