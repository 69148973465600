import getEnv from '../env';
import { log } from '../log';

import { getEnvSize } from '../parameters';

const env = getEnv();

const generateUUIDv4 = (): string =>
  'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = Math.floor(Math.random() * 16);
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const getUuid = (): string => {
  const storageUuid = env.localStorage.getItem('bdx-uuid');
  if (storageUuid !== null) {
    return storageUuid;
  }
  const uuid = generateUUIDv4();
  env.localStorage.setItem('bdx-uuid', uuid);
  return uuid;
};

export const getScreenResolution = (): { width: string; height: string } => {
  try {
    const { width, height } = getEnvSize();
    return { width: String(width), height: String(height) };
  } catch (error) {
    log.error(error);
    return { width: '', height: '' };
  }
};

export const getScreenResolutionFormatted = (): string => {
  const resolution = getScreenResolution();
  if (resolution.width === '') {
    return '';
  }
  return `${resolution.width}x${resolution.height}`;
};

export const referrer = (): string => {
  try {
    return env.top?.document.referrer || '';
  } catch (_e) {
    return '';
  }
};
