import {
  ActorRef,
  AnyActorRef,
  MachineSnapshot,
  MetaObject,
  NonReducibleUnknown,
  StateSchema,
} from 'xstate';

export interface TapMachineContext {
  taps: number;
  element: Element;
  repetitions: number;
  fingers: number;
  password: string;
  lastTouch: number;
  timeout: number;
}

export enum ELEMENT_TAP_EVENTS {
  TOUCH = 'TOUCH',
  REPEATED = 'REPEATED',
}

export interface ValidTouchEvent {
  type: ELEMENT_TAP_EVENTS.TOUCH;
  data: number;
}
export interface RepeatedEvent {
  type: ELEMENT_TAP_EVENTS.REPEATED;
}

export enum ACTIONS {
  RESET_TAPS = 'RESET_TAPS',
  INCREMENT_TAPS = 'INCREMENT_TAPS',
  UPDATE_LAST_TOUCH = 'UPDATE_LAST_TOUCH',
  RAISE_REPEATED_EVENT = 'RAISE_REPEATED_EVENT',
  RECORD_TOUCH = 'RECORD_TOUCH',
}
export enum GUARDS {
  EXCEEDED_REPETITIONS = 'EXCEEDED_REPETITIONS',
  EVENT_WITHIN_TIMEOUT = 'EVENT_WITHIN_TIMEOUT',
}
export enum STATES {
  TOUCHING = 'TOUCHING',
  CONFIRMING = 'CONFIRMING',
  FINAL = 'FINAL',
}
export type ElementTapMachineSnapshot = MachineSnapshot<
  TapMachineContext,
  AnyElementTapEvent,
  Record<string, AnyActorRef | undefined>,
  STATES,
  string,
  NonReducibleUnknown,
  MetaObject,
  StateSchema
>;
export type ElementTapActorRef = ActorRef<ElementTapMachineSnapshot, AnyElementTapEvent>;

export type AnyElementTapEvent = ValidTouchEvent | RepeatedEvent;
