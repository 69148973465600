import { EventObject, fromCallback } from 'xstate';
import { getEnv } from '@repo/utils';
import {
  BordeauxMachineContext,
  EVENTS,
  AdUnitMode,
  HideAnchoredAdsEvent,
  ShowAnchoredAdsEvent,
  DeviceOptions,
} from '@repo/shared-types';
import isAnchoredHideable from './is-anchored-hidable';

const anchoredLogic = fromCallback<
  EventObject,
  Pick<BordeauxMachineContext, 'ads' | 'config' | 'slots' | 'pageParameters'>
>(({ sendBack, input }) => {
  const isMobile = input.pageParameters.device === DeviceOptions.MOBILE;

  const env = getEnv();

  if (env.vanilla && isMobile) {
    const checkAds = () => {
      const tallSlotsOnPage = input.slots
        .getValues()
        .filter(element => element.getProperty('height') && element.getProperty('height') >= 600)
        .map(element => element.getProperty('id'));

      const ads = input.ads.getValues();
      const slotsInView = ads
        .filter(ad => ad.getProperty('mode') !== AdUnitMode.ANCHORED)
        .some(ad => ad.getProperty('inViewport') === true);

      const tallSlotsInView = ads
        .filter(
          ad =>
            ad.getProperty('inView75Percent') === true &&
            (ad.getProperty('height') as number) >= 600,
        )
        .some(ad => tallSlotsOnPage.includes(ad.getProperty('slotID') as string));
      const anchoredHidable = isAnchoredHideable(
        ads,
        input.config.features.customActivations.HIDEABLE_ANCHORED_ENABLED.LINE_ITEM,
      );

      if ((anchoredHidable && slotsInView) || (!anchoredHidable && tallSlotsInView)) {
        sendBack({
          type: EVENTS.HIDE_ANCHORED_ADS,
        } as HideAnchoredAdsEvent);
      } else {
        sendBack({
          type: EVENTS.SHOW_ANCHORED_ADS,
        } as ShowAnchoredAdsEvent);
      }
    };

    // Avoid updating too much, but make sure it's at most 20ms delay
    let changeCall: number | null = null;
    const onAdChange = () => {
      if (changeCall !== null) return;
      changeCall = env.setTimeout(() => {
        checkAds();
        changeCall = null;
      }, 20);
    };

    const subscribed = {};
    input.ads.subscribe(ads => {
      ads.forEach(ad => {
        const id = ad.getProperty('id');
        if (subscribed[id]) return;
        subscribed[id] = true;
        ad.subscribe(onAdChange);
      });
    });
  }

  setTimeout(() => {
    const infiniteContainerElement = env.document.querySelector('.infinite-container') || null;

    if (
      !(
        env.vanilla?.featureFlags?.infiniteScroll?.enabled === true &&
        infiniteContainerElement instanceof Element
      )
    )
      return;

    const oberverCallback = (entries: IntersectionObserverEntry[]): void => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          sendBack({
            type: EVENTS.HIDE_ANCHORED_ADS,
            data: { infiniteScrollIntersection: true },
          } as HideAnchoredAdsEvent);
        } else {
          sendBack({
            type: EVENTS.SHOW_ANCHORED_ADS,
            data: { infiniteScrollIntersection: true },
          } as ShowAnchoredAdsEvent);
        }
      });
    };

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver(oberverCallback, observerOptions);
    observer.observe(infiniteContainerElement);
  }, 3000);
});

export default anchoredLogic;
