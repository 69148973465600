import { getEnv } from '@repo/utils';
import { ThirdParty, ThirdPartyAPIMachineConfigs } from '@repo/shared-types';
import loadScript from 'third-party-apis/utils/load-script';
import { PartialPick } from '@repo/utils';

const config: PartialPick<ThirdPartyAPIMachineConfigs[ThirdParty.PUBMATIC], 'thirdParty'> = {
  thirdParty: ThirdParty.PUBMATIC,
  methods: {
    getScriptLocation: ({
      data: {
        config: { pubId, profileId },
      },
    }) => {
      const env = getEnv();
      const purl = env.location.href;
      let profileVersionId = '';
      if (purl.indexOf('pwtv=') > 0) {
        const regexp = /pwtv=(.*?)(&|$)/g;
        const matches = regexp.exec(purl);
        if (matches && matches.length >= 2 && matches[1].length > 0) {
          profileVersionId = `/${matches[1]}`;
        }
      }
      return `https://ads.pubmatic.com/AdServer/js/pwt/${pubId}/${profileId}${profileVersionId}/pwt.js`;
    },
    loadScript: scriptLocation => loadScript(scriptLocation, 'bordeaux-pubmatic', { async: true }),
  },
};
export default config;
