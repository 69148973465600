import { SlotLabel } from '../slot/index.types';
import { Targeting } from '../targeting/index.types';
import { GPTOutput } from './gpt.types';
import { ALL_COUNTRIES_ENABLED, FLUID_SIZE } from './constants';
import { PartialPick } from '@repo/utils/src';

export interface Rule {
  category: string;
  distance: number | 'screenheight';
}

export interface Rules {
  slots?: Array<Rule>;
  ads?: Array<Rule>;
  tiles?: Array<Rule>;
  ownCategory?: boolean;
}

export enum AdUnitMode {
  ANCHORED = 'anchored',
  INTERSTITIAL = 'interstitial',
  OOP = 'oop',
  SKYSCRAPER = 'skyscraper',
  SLOTIFY = 'slotify',
}
export enum AdUnitCategory {
  MPU = 'mpu',
  DMPU = 'dmpu',
  LEADERBOARD = 'leaderboard',
  SPONSORED_POST = 'sponsored-post',
  SPONSORED_BRAND = 'sponsored-brand',
  INFINITE_SCROLL = 'infinite-scroll',
}
export enum AdUnitStatus {
  PENDING = 'pending',
  DELIVERED = 'delivered',
  UNDELIVERED = 'undelivered',
  VIEWED = 'viewed',
  INVALID = 'invalid',
}
export enum AdUnitIssues {
  MISSING_AFFINITY = 'missing-affinity',
  AFFINITY_OCCUPIED = 'affinity-occupied',
  NO_AFFINITY = 'no-affinity',
}
export enum AdUnitPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export type AdUnitSizeTuple = [number, number];
export type AdUnitSize = typeof FLUID_SIZE | AdUnitSizeTuple;
export type AdUnitSizes = Array<AdUnitSize>;

export interface AdDefinitionProperties {
  category?: AdUnitCategory;

  position?: AdUnitPosition;
  topPosition: number;
  verticallyCentered: boolean;

  fluid?: boolean;

  closeButton: boolean;

  inRoadblock?: boolean;
  roadblockIncremental?: boolean;

  refresh: boolean;
  refreshDisabled: boolean;
  refreshDisabledSizes: Array<[number, number]>;

  enabledCountries: Array<string> | typeof ALL_COUNTRIES_ENABLED;
  disabledCountries: Array<string>;

  categoryAvoidanceRules: Rules;
  ignoreCategoryAvoidance: boolean;
}

export interface AdDefinition extends AdDefinitionProperties {
  name: string;
  mode: AdUnitMode;
  incremental: boolean;
  sizes: AdUnitSizes;
  targeting: Targeting;
  width: number;
  height: number;
  lazyload: boolean;
  requestOrder: number;
  nativeContent: boolean;

  affinitySlotID?: string;
}

export type AdElementGroup = PartialPick<
  Record<'element' | 'innerContainer' | 'outerContainer' | 'closeButton', HTMLElement>,
  'element'
>;

export interface Ad extends AdDefinition {
  id: string;
  adUnitPath: string;
  status: AdUnitStatus;

  inView: boolean;
  inView75Percent: boolean;
  viewed: boolean;
  inViewport: boolean;
  viewedTime: number;
  fetchTime: number;
  loadTime: number;

  issue?: AdUnitIssues;
  gptOutput?: GPTOutput;
  elements?: AdElementGroup;
  auctionId?: number;
  slotID?: string;
  label?: SlotLabel;
}
