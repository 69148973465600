import fastdom from '../fastdom';
import { log } from '../log';
import { sentry } from '../sentry';

const getDimension = (value: number) => {
  return value ? `${value}px` : '100%';
};

const getElementByAdUnit = <T extends Element = Element>(
  selector: string,
  slot: googletag.Slot,
): T | null => {
  const id = slot.getSlotElementId();
  const parentDivEle = document.getElementById(id);
  return (parentDivEle && parentDivEle.querySelector<T>(selector)) || null;
};

const resizeGamCreativeContainerSize = (slot: googletag.Slot, width: number, height: number) => {
  fastdom
    .mutate(() => {
      // resize both container div + iframe
      ['div', 'iframe'].forEach(elmType => {
        const element = getElementByAdUnit<HTMLElement>(
          `${elmType}:not([style*="display: none"])`,
          slot,
        );
        if (element) {
          const elementStyle = element.style;
          elementStyle.width = getDimension(width);
          elementStyle.height = getDimension(height);
        } else {
          const error = `Unable to locate matching page element for slot : ${slot.getSlotElementId()}. Can’t resize it to ad’s dimensions. Please review setup.`;
          sentry.reportError(error);
          log.error(error);
        }
      });
    })
    .catch(error => {
      if (error instanceof Error) {
        log.error(error.message);
      }
    });
};

export default resizeGamCreativeContainerSize;
