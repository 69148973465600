import { log } from './log';

export default (commands: Array<() => void>): void => {
  if (!Array.isArray(commands)) {
    log.error('Command queue commands is not an array');
    return;
  }

  const pushToQueue = (command: () => void): number => {
    if (typeof command !== 'function') {
      log.error(
        `Command queue will only accept a function callback command, '${typeof command}' given.`,
      );
    } else {
      command();
    }
    return 0;
  };

  commands.forEach(command => {
    command();
  });
  commands.push = pushToQueue;
};
