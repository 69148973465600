import { log } from '@repo/utils';
import { SlotObserverCallback, SlotObserverData, SlotObserverEventType } from './index.types';
import { calculatePosition, getObserver, noop } from './utils';

class SlotObserver {
  private isInsideViewport = false;

  private isNewInViewport = false;

  private isInsideBuffer = false;

  private isNewInBuffer = false;

  private scrollPosition = 0;

  private buffer = 0;

  private callback: SlotObserverCallback | null = null;

  private lastUpdate = 0;

  private slotName: string;

  private bufferUnObserve: () => void;

  private viewportUnObserve: () => void;

  /**
   * Sets up and initiates a new observer for Bordeaux Slots.
   */
  constructor(element: HTMLElement, slotName: string, buffer = 0) {
    this.buffer = buffer;
    this.slotName = slotName;

    if (buffer !== 0) {
      this.bufferUnObserve = getObserver(
        element,
        entry => {
          if (entry.time < this.lastUpdate) return;

          const isInsideBuffer = entry.intersectionRatio > 0;
          const isNewInBuffer = isInsideBuffer && !this.isInsideBuffer;

          this.scrollPosition = calculatePosition(entry, this.buffer);
          this.isInsideBuffer = isInsideBuffer;
          this.isNewInBuffer = !isInsideBuffer ? false : isNewInBuffer;
          this.lastUpdate = entry.time;

          this.next(this.composeEvent('BUFFER'));
        },
        this.buffer,
      );
    } else {
      this.bufferUnObserve = noop;
    }

    this.viewportUnObserve = getObserver(
      element,
      async (entry: IntersectionObserverEntry) => {
        if (entry.time < this.lastUpdate) return;

        const isInsideViewport = entry.isIntersecting;
        const isNewInViewport = isInsideViewport && !this.isInsideViewport;

        this.scrollPosition = calculatePosition(entry, 0);
        this.isInsideViewport = isInsideViewport;
        this.isNewInViewport = !isInsideViewport ? false : isNewInViewport;
        this.lastUpdate = entry.time;

        this.next(this.composeEvent('VIEWPORT'));
      },
      0,
    );
  }

  /**
   * Executes callback with SlotObserverData when slot:
   *  1. Leaves or enters the buffer or viewport.
   *  2. Is 100% inside the buffer or viewport.
   */
  public observe(callback: SlotObserverCallback): this {
    if (this.callback !== null) {
      log.warn(`SlotObserver - ${this.slotName}: Unable to call method observe() more than once`);
      return this;
    }
    this.callback = callback;
    return this;
  }

  /**
   * Stops observering the slot and disconnects associated IntersectionObservers.
   */
  public unobserve(): this {
    this.callback = null;
    this.bufferUnObserve();
    this.viewportUnObserve();
    return this;
  }

  private composeEvent(type: SlotObserverEventType): SlotObserverData {
    return {
      type,
      ...(type === 'BUFFER'
        ? { isIntersecting: this.isInsideBuffer, newInIntersection: this.isNewInBuffer }
        : { isIntersecting: this.isInsideViewport, newInIntersection: this.isNewInViewport }),
      scrollPosition: this.scrollPosition,
    };
  }

  private next(data: SlotObserverData): void {
    if (this.callback === null) {
      return;
    }
    this.callback(data);
  }
}

export default SlotObserver;
