import { log } from '@repo/utils';
import { getEnv } from '@repo/utils';
import { fromCallback } from 'xstate';
import {
  ExpandHeightAdFeatureEvent,
  FullWidthAdFeatureEvent,
  MultiFrameAdFeatureEvent,
  PopOutAdFeatureEvent,
  AnyAdFeatureResponseEvent,
  AD_FEATURES_EVENTS,
  AD_FEATURE_NAMES,
} from '@repo/shared-types';

const getIframe = (wind: MessageEventSource): HTMLIFrameElement | null => {
  const env = getEnv();
  return (
    Array.from(env.document.getElementsByTagName('iframe')).find(iframe => {
      return iframe.contentWindow === wind;
    }) || null
  );
};
const listenForAdFeatures = fromCallback<AnyAdFeatureResponseEvent>(({ sendBack }) => {
  const env = getEnv();
  env.addEventListener('message', event => {
    if (!event.data) {
      return;
    }
    if (!event.source) {
      return;
    }

    if (typeof event.data !== 'object') {
      return;
    }
    if (event.data.type !== 'bordeaux-ad-feature') {
      return;
    }

    const iframe = getIframe(event.source);
    if (!iframe) {
      log.error(
        `Bordeaux ad feature could not be initiated because message came from an unknown iframe.`,
      );
      return;
    }

    const slotElement = iframe.closest('.bordeaux-slot');
    switch (event.data.bordeauxFeature) {
      case AD_FEATURE_NAMES.FULL_WIDTH:
        if (!slotElement) {
          log.error(
            `Bordeaux ad feature could not be initiated because message came from an unknown slot.`,
          );
          return;
        }
        sendBack({
          type: AD_FEATURES_EVENTS.FULL_WIDTH,
          data: {
            slotID: slotElement.getAttribute('id'),
          },
        } as FullWidthAdFeatureEvent);
        break;

      case AD_FEATURE_NAMES.EXPAND_HEIGHT:
        if (!slotElement) {
          log.error(
            `Bordeaux ad feature could not be initiated because message came from an unknown slot.`,
          );
          return;
        }
        sendBack({
          type: AD_FEATURES_EVENTS.EXPAND_HEIGHT,
          data: {
            slotID: slotElement.getAttribute('id'),
            height: event.data.height,
            expandChildren: event.data.expandChildren,
            source: event.source,
          },
        });
        break;
      case AD_FEATURES_EVENTS.EXPAND_HEIGHT:
        if (!slotElement) {
          log.error(
            `Bordeaux ad feature could not be initiated because message came from an unknown slot.`,
          );
          return;
        }
        sendBack({
          type: AD_FEATURES_EVENTS.EXPAND_HEIGHT,
          data: {
            slotID: slotElement.getAttribute('id'),
            height: event.data.height,
            source: event.source,
          },
        } as ExpandHeightAdFeatureEvent);
        break;
      case AD_FEATURE_NAMES.POP_OUT:
        if (!slotElement) {
          log.error(
            `Bordeaux ad feature could not be initiated because message came from an unknown slot.`,
          );
          return;
        }
        sendBack({
          type: AD_FEATURES_EVENTS.POP_OUT,
          data: {
            slotID: slotElement.getAttribute('id'),
            creativeID: event.data.creativeID,
            distance: event.data.distance || 0,
            edge: event.data.edge || 'top',
            expandedHeight: event.data.expandedHeight || 0,
            expandedFrame: event.data.expandedFrame || '',
          },
        } as PopOutAdFeatureEvent);
        break;
      case AD_FEATURE_NAMES.MULTI_FRAME:
        sendBack({
          type: AD_FEATURES_EVENTS.MULTI_FRAME,
          data: {
            creativeID: event.data.creativeID,
            name: event.data.name || 'main',
            frame: iframe,
          },
        } as MultiFrameAdFeatureEvent);
        break;
      default:
        log.error(
          `Bordeaux ad feature ${event.data.bordeauxFeature} does not exist. Features should be detected for before used.`,
        );
    }
  });
});
export default listenForAdFeatures;
