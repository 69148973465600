import { getEnv } from '@repo/utils';
import { triggerError } from '@repo/utils';

const env = getEnv();

export default (message = 'Triggered from the console'): void => {
  env.setTimeout(() => {
    triggerError(message);
  });
};
