import { ALL_COUNTRIES_ENABLED, AdDefinition } from '@repo/shared-types';

const checkAdUnitCountries =
  (currentCountry: string) =>
  (adUnit: AdDefinition): boolean => {
    if (
      adUnit.enabledCountries !== ALL_COUNTRIES_ENABLED &&
      !adUnit.enabledCountries.includes(currentCountry)
    ) {
      return false;
    }
    if (adUnit.disabledCountries.includes(currentCountry)) {
      return false;
    }
    return true;
  };

export default checkAdUnitCountries;
