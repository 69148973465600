import { timeData } from '@repo/utils';
import { API_EVENTS_OUT } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';

export default (service: ApiMachineEventService): void => {
  service.sendEvent({
    type: API_EVENTS_OUT.INITIALISE,
    data: timeData(),
  });
};
