import { log } from '@repo/utils';
import { getEnv, getParam, AbTest, TimeoutError } from '@repo/utils';
import { SommelierResponse } from '@repo/shared-types';

const env = getEnv();

const HOST = 'https://sommelier.futurehybrid.tech';
const FORCE_ABTEST_PARAMETER = 'force_abtest';
const TIMEOUT_DURATION_MS = 5000;
const TIMEOUT_ERROR_MESSAGE = `Took more than ${TIMEOUT_DURATION_MS}ms to resolve and timed out`;

export const getForceAbtests = (): string => {
  const abTestsFromLocation = AbTest.splitAbTests(getParam(FORCE_ABTEST_PARAMETER));
  const abTestFromSessionStorage = AbTest.splitAbTests(
    env.sessionStorage.getItem(FORCE_ABTEST_PARAMETER) || '',
  );

  const forceAbtests = AbTest.flattenAbTests({
    ...abTestsFromLocation,
    ...abTestFromSessionStorage,
  });

  if (forceAbtests) {
    env.sessionStorage.setItem(FORCE_ABTEST_PARAMETER, forceAbtests);
  }

  return forceAbtests;
};

export interface RequestParams {
  templateName?: string;
  location?: string;
  screenWidth?: string;
  forcePlacementDb?: string;
  forceAbtest?: string;
  gaSessionId?: string;
}

const defaultParams: RequestParams = {
  templateName: '',
  location: '',
  screenWidth: '',
};

export const buildRequestParams = (overrides: Partial<RequestParams> = {}): RequestParams => {
  const forceAbtest = getForceAbtests();

  return {
    ...defaultParams,
    ...overrides,
    forceAbtest,
    forcePlacementDb: getParam('force_plc_db') || undefined,
  };
};

export const executeRequest = async (params: RequestParams): Promise<SommelierResponse> => {
  const queryParams = new URLSearchParams({
    r: Math.round(Math.random() * 1000).toString(),
    tpl: params.templateName || '',
    l: params.location || '',
    sw: params.screenWidth || '',
  });

  if (params.forcePlacementDb) {
    queryParams.append('force_plc_db', params.forcePlacementDb);
  }

  if (params.forceAbtest) {
    queryParams.append('fabt', params.forceAbtest);
  }

  if (params.gaSessionId) {
    queryParams.append('sid', params.gaSessionId);
  }

  const url = `${HOST}/config/?${queryParams.toString()}`;

  return Promise.race<SommelierResponse>([
    fetch(url, { credentials: 'omit', cache: 'no-store' })
      .then(async response => {
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        return (await response.json()) as SommelierResponse;
      })
      .catch(error => {
        log.warn(`AdServer /config request error: ${error}`);
        return Promise.reject(error);
      }),
    new Promise<never>((_, reject) =>
      setTimeout(() => reject(new TimeoutError(TIMEOUT_ERROR_MESSAGE)), TIMEOUT_DURATION_MS),
    ),
  ]);
};

export const sommelierRequest = (overrides: Partial<RequestParams> = {}) => {
  const params = buildRequestParams(overrides);
  return executeRequest(params);
};

export default sommelierRequest;
