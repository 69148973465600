import GenericGuardArgs from '../../../state/proxy/generic-guard-args.types';
import {
  ActorRef,
  AnyActorRef,
  EventObject,
  MachineSnapshot,
  MetaObject,
  NonReducibleUnknown,
  StateSchema,
} from 'xstate';

export enum REFRESH_GUARDS {
  USER_ACTIVE_RECENTLY = 'USER_ACTIVE_RECENTLY',
  FEATURE_ENABLED = 'FEATURE_ENABLED',
  FEATURE_DISABLED = 'FEATURE_DISABLED',
}

export enum REFRESH_EVENTS {
  SET_ROADBLOCK = 'SET_ROADBLOCK',
  CHECK = 'CHECK',
  SET_REFRESH_PAUSED = 'SET_REFRESH_PAUSED',
  SET_FEATURE_ENABLED = 'SET_FEATURE_ENABLED',
}

export interface CheckEvent extends EventObject {
  type: REFRESH_EVENTS.CHECK;
}
export interface SetRoadblockRefreshEvent extends EventObject {
  type: REFRESH_EVENTS.SET_ROADBLOCK;
  data: boolean;
}
export interface SetFeatureEnabledEvent extends EventObject {
  type: REFRESH_EVENTS.SET_FEATURE_ENABLED;
  data: boolean;
}
export interface SetRefreshPausedEvent extends EventObject {
  type: REFRESH_EVENTS.SET_REFRESH_PAUSED;
  data: boolean;
}

export enum USER_ACTIVITY_EVENTS {
  DOCUMENT_VISIBILITY = 'DOCUMENT_VISIBILITY',
  MOUSE_MOVE = 'MOUSE_MOVE',
  SCROLL = 'SCROLL',
}

export interface DocumentVisibilityEvent extends EventObject {
  type: USER_ACTIVITY_EVENTS.DOCUMENT_VISIBILITY;
  data: boolean;
}

export interface MouseMoveEvent extends EventObject {
  type: USER_ACTIVITY_EVENTS.MOUSE_MOVE;
  data: number;
}

export interface ScrollEvent extends EventObject {
  type: USER_ACTIVITY_EVENTS.SCROLL;
  data: number;
}

export type AnyUserActivityEvent = DocumentVisibilityEvent | MouseMoveEvent | ScrollEvent;
export type AnySetDataEvent = SetRoadblockRefreshEvent | SetFeatureEnabledEvent | SetRefreshPausedEvent;
export type AnyAutomaticRefreshEvent = CheckEvent | AnyUserActivityEvent | AnySetDataEvent;

export interface RefreshMachineContext {
  documentVisible: boolean;
  mouseMoved: number;
  scrolled: number;

  activityTimeout: number;
  updateInterval: number;
  userActivityMachine: ActorRef<any, any>;

  refreshPaused: boolean;
  isRoadblocked: boolean;
  featureEnabled: boolean;
}

export enum REFRESH_STATES {
  SETUP = 'SETUP',
  WAIT = 'WAIT',
  UPDATE = 'UPDATE',
  STOP = 'STOP',
}

export type AutomaticRefreshGuardArgs = GenericGuardArgs<
  RefreshMachineContext,
  AnyAutomaticRefreshEvent
>;

export type AutomaticRefreshMachineSnapshot = MachineSnapshot<
  RefreshMachineContext,
  AnyAutomaticRefreshEvent,
  Record<string, AnyActorRef | undefined>,
  REFRESH_STATES,
  string,
  NonReducibleUnknown,
  MetaObject,
  StateSchema
>;
export type AutomaticRefreshActorRef = ActorRef<
  AutomaticRefreshMachineSnapshot,
  AnyAutomaticRefreshEvent
>;
