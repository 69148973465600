import getEnv from '../../env';
import { log } from '../../log';

import { GPPPingReturn, GPPAPI, GPPAPICommandTypeMap } from '@repo/shared-types';
import findAPI, { CMP_TIMEOUT } from './utils';

const mockGPPAPI = (): GPPAPI => {
  // https://github.com/InteractiveAdvertisingBureau/Global-Privacy-Platform/blob/main/Core/CMP%20API%20Specification.md#examples
  let listenerId = 0;
  return (command, callback): void => {
    const pingData: GPPPingReturn = {
      gppVersion: '-1',
      cmpStatus: 'stub',
      cmpDisplayStatus: 'hidden',
      signalStatus: 'not ready',
      supportedAPIs: [],
      cmpId: -1,
      sectionList: [],
      applicableSections: [],
      gppString: '',
      parsedSections: {},
      mocked: true,
    };

    switch (command) {
      case 'ping':
        (callback as GPPAPICommandTypeMap['ping'])(pingData, true);
        break;
      case 'addEventListener': {
        (callback as GPPAPICommandTypeMap['addEventListener'])(
          {
            eventName: 'listenerRegistered',
            listenerId,
            data: true,
            pingData,
          },
          true,
        );
        listenerId++;
        break;
      }
      default:
        log.error(`Unknown command for mocked __gpp: ${command}`);
    }
  };
};

export default async (): Promise<GPPAPI> => {
  const env = getEnv();
  const gppapiPromise = new Promise<GPPAPI>((resolve, reject) => {
    const gppapi = findAPI('__gpp');

    if (gppapi === null) {
      reject(new Error('__gpp API does not exist in window frames'));
      return;
    }

    resolve(gppapi);
  });

  const timeoutPromise = new Promise<GPPAPI>((_resolve, reject) => {
    env.setTimeout(
      () => reject(new Error(`Timeout retrieving __gpp API after ${CMP_TIMEOUT}ms`)),
      CMP_TIMEOUT,
    );
  });

  return Promise.race([gppapiPromise, timeoutPromise]).catch(reason => {
    log.error(reason);

    return mockGPPAPI();
  });
};
