import getEnv from '../env';
import { ActionArgs } from '@repo/shared-types';
import { BrowserOptions, DeviceOptions, PageParameters } from '@repo/shared-types';

import getBrowser from './browser';
import getDevice from './device';
import getCountry from './country';
import getSite from './site';

const env = getEnv();

export default ({ context }: ActionArgs): PageParameters => {
  const browser: BrowserOptions = getBrowser();
  const device: DeviceOptions = getDevice();
  const country: string = getCountry(context);
  const site: string = getSite();

  return {
    browser,
    content: env.adParameters ? env.adParameters.content : '',
    device,
    country,
    site,
    type: env.adParameters ? env.adParameters.type : '',
  };
};
