import { BordeauxConfig } from '../../zod-schemas/config/zod-schema';
import { SommelierResponse } from '../sommelier/sommelier-response/index.types';

export enum METRICS {
  CONFIG = 'CONFIG',
  AUCTIONS = 'AUCTIONS',
  THIRD_PARTY = 'THIRD_PARTY',
  THIRD_PARTY_SCRIPTS = 'THIRD_PARTY_SCRIPTS',
}

export enum GUARDS {
  TIMING_COLLECTION_ENABLED = 'TIMING_COLLECTION_ENABLED',
  ERROR_IS_TIMEOUT = 'ERROR_IS_TIMEOUT',
  DUPLICATE_SCRIPTS = 'DUPLICATE_SCRIPTS',
  ROADBLOCK_INCREMENTALS_FILLED = 'ROADBLOCK_INCREMENTALS_FILLED',
  PROCESSING_SLOTS_CURRENTLY = 'PROCESSING_SLOTS_CURRENTLY',
  SLOTS_TO_PROCESS = 'SLOTS_TO_PROCESS',
}
export enum STATES {
  INITIALISING = 'INITIALISING',
  SCRAPING_PARAMATERS = 'SCRAPING_PARAMATERS',
  FETCHING_CONFIG = 'FETCHING_CONFIG',
  CHECKING_CONFIG = 'CHECKING_CONFIG',
  PARSING_CONFIG = 'PARSING_CONFIG',
  RETRIEVING_CONSENT = 'RETRIEVING_CONSENT',
  LOADING_THIRD_PARTIES = 'LOADING_THIRD_PARTIES',
  DECIDING_PAGE_STYLE_CONSTANTS = 'DECIDING_PAGE_STYLE_CONSTANTS',

  HANDLING_SLOTS = 'HANDLING_SLOTS',

  ERROR = 'ERROR',
  WAIT_FOR_INITIALISATION = 'WAIT_FOR_INITIALISATION',
}

export enum ACTIONS {
  USE_PARSED_FALLBACK_CONFIG = 'USE_PARSED_FALLBACK_CONFIG',
  USE_FALLBACK_CONFIG = 'USE_FALLBACK_CONFIG',

  RAISE_OPEN_AD_TOOL = 'RAISE_OPEN_AD_TOOL',
  CREATE_API_MACHINE = 'CREATE_API_MACHINE',
  INITIALISE_FEATURES = 'INITIALISE_FEATURES',

  INITIALISE_SENTRY = 'INITIALISE_SENTRY',

  THIRD_PARTIES_READY = 'THIRD_PARTIES_READY',
  SEND_AB_TEST_TO_FREYR = 'SEND_AB_TEST_TO_FREYR',
  DECIDE_AVOIDANCE_DISTANCE = 'DECIDE_AVOIDANCE_DISTANCE',
  DECIDE_THIRD_PARTY_CONFIG = 'DECIDE_THIRD_PARTY_CONFIG',
  DECIDE_PAGE_ADUNIT_PATH = 'DECIDE_PAGE_ADUNIT_PATH',
  DECIDE_VALID_ADUNITS = 'DECIDE_VALID_ADUNITS',
  SET_SUBSYSTEMS_CONFIG = 'SET_SUBSYSTEMS_CONFIG',
  SETUP_CUSTOM_ACTIVATIONS = 'SETUP_CUSTOM_ACTIVATIONS',
  DECIDE_REFRESH_TIME = 'DECIDE_REFRESH_TIME',
  DECIDE_ROADBLOCK_INCREMENTALS = 'DECIDE_ROADBLOCK_INCREMENTALS',
  SETUP_USER_SYNC = 'SETUP_USER_SYNC',
  SETUP_AD_MANAGER = 'SETUP_AD_MANAGER',
  HANDLE_DYNAMIC_SLOTS = 'HANDLE_DYNAMIC_SLOTS',
  HANDLE_GENERATED_SLOTS = 'HANDLE_GENERATED_SLOTS',
  HANDLE_ERROR = 'HANDLE_ERROR',

  CHECK_AD_BLOCK = 'CHECK_AD_BLOCK',
  READ_QUERY_PARAMETERS = 'READ_QUERY_PARAMETERS',
  READ_PAGE_PARAMETERS = 'READ_PAGE_PARAMETERS',
  CREATE_SHAMEFUL_MACHINE = 'CREATE_SHAMEFUL_MACHINE',
  ASSIGN_INDEX_EXCHANGE_DEVICE_TYPE = 'ASSIGN_INDEX_EXCHANGE_DEVICE_TYPE',
  ASSIGN_BORDEAUX_ADS_PROMISE = 'ASSIGN_BORDEAUX_ADS_PROMISE',
  CHECK_MULTIPLE_SCRIPTS = 'CHECK_MULTIPLE_SCRIPTS',
  CREATE_ADTOOL_TAP_OPEN_MACHINE = 'CREATE_ADTOOL_TAP_OPEN_MACHINE',
  CHECK_ADTOOL_PARAM = 'CHECK_ADTOOL_PARAM',
  CREATE_AUTOMATIC_REFRESH_MACHINE = 'CREATE_AUTOMATIC_REFRESH_MACHINE',
  CREATE_AD_FEATURE_MACHINE = 'CREATE_AD_FEATURE_MACHINE',
  REPORT_IF_AD_BLOCKED = 'REPORT_IF_AD_BLOCKED',
  DECIDE_LIVE_INTENT_USER_SYNC = 'DECIDE_LIVE_INTENT_USER_SYNC',
  DECIDE_TEST_PUBX = 'DECIDE_TEST_PUBX',
  DECIDE_TEST_ID_SERVICE_ACTIVATION = 'DECIDE_TEST_ID_SERVICE_ACTIVATION',
  ASSIGN_LIVE_INTENT_USER_SYNC_TARGETING = 'ASSIGN_LIVE_INTENT_USER_SYNC_TARGETING',

  STORE_HYBRID_TEST_SESSIONS = 'STORE_HYBRID_TEST_SESSIONS',
  SEND_HYBRID_ID_TO_FREYR = 'SEND_HYBRID_ID_TO_FREYR',
  DECIDE_TEST_AUCTION_TIMEOUTS = 'DECIDE_TEST_AUCTION_TIMEOUTS',

  SHOW_ANCHORED = 'SHOW_ANCHORED',
  HIDE_ANCHORED = 'HIDE_ANCHORED',
}

export enum PAGE_STYLE_CONSTANTS {
  PAGE_BACKGROUND = 'page_background',
  PAGE_TEXT = 'page_text',
}

export interface BordeauxMachineConfigEvent {
  type: string;
  data: BordeauxConfig;
}
export interface BordeauxMachineSommelierResponseEvent {
  type: string;
  data: SommelierResponse;
}
