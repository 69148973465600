import { fromCallback } from 'xstate';
import { getEnvLoad, getEnvUnload } from '@repo/utils';
import { EVENTS } from '@repo/shared-types';

const pageEventEmitter = fromCallback(({ sendBack }) => {
  getEnvUnload().then(timeData => {
    sendBack({
      type: EVENTS.PAGE_UNLOAD,
      data: timeData,
    });
  });

  getEnvLoad().then(timeData => {
    sendBack({
      type: EVENTS.PAGE_LOAD,
      data: timeData,
    });
  });
});
export default pageEventEmitter;
