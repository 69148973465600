import { PreBidEventData, API_EVENTS_OUT } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';

export const setPrebidAnalyticsEnabled = (service: ApiMachineEventService): void => {
  service.sendEvent({
    type: API_EVENTS_OUT.SET_PREBID_ANALYTICS_ENABLED,
    data: true,
  });
};

export const setPrebidAnalyticsDisabled = (service: ApiMachineEventService): void => {
  service.sendEvent({
    type: API_EVENTS_OUT.SET_PREBID_ANALYTICS_ENABLED,
    data: false,
  });
};

// TODO: THis needs sorting

let prebidAnalyticsData: Array<PreBidEventData> = [];

export const setPrebidAnalytics = (data: Array<PreBidEventData>): void => {
  prebidAnalyticsData = data;
};

export default (): Array<PreBidEventData> => {
  return prebidAnalyticsData;
};
