import { Targeting } from '@repo/shared-types';

export default (targeting: Targeting) => {
  return Object.fromEntries(
    Object.entries(targeting).map(([key, value]) => {
      if (Array.isArray(value)) {
        return [
          key,
          value.map(item => (typeof item === 'string' ? item.replace(/&amp;/g, 'and') : item)),
        ];
      }
      if (typeof value === 'string') {
        return [key, value.replace(/&amp;/g, 'and')];
      }
      return [key, value];
    }),
  );
};
