import { getEnv, getPermutiveSegments, timeData, validSizes } from '@repo/utils';
import { generateRAMPRequest } from '@repo/utils';
import { Bid, Ad, AdUnitMode, AdUnitSizes, ThirdParty, DataObject } from '@repo/shared-types';
import { log } from '@repo/utils';
import { includes } from 'ramda';
import {
  Partner,
  REPORT_AUCTION_PARTNER,
  BordeauxMachineContext,
  BordeauxMachineService,
} from '@repo/shared-types';
import { AdServerAdvert } from '../../index.types';
import {
  initializeCreativeAdHandlerSafeframe,
  initializeCreativeAdViewHandlerSafeframe,
  attachPageTargeting,
} from '@repo/utils';

const env = getEnv();
const storedBids = new Map<string, Bid>();
const PURCH_FLOOR_BIDDER_ID = '1';

function processBids(bids: Array<Bid> | undefined): void {
  if (!bids) {
    return;
  }

  const slots = env.googletag && env.googletag.pubads().getSlots();
  if (!slots) {
    return;
  }

  slots.forEach(slot => {
    const slotElementId = slot.getSlotElementId();
    const bid = bids.find(checkBid => checkBid.divid === slotElementId);
    if (bid && bid.bidder.bdrid !== PURCH_FLOOR_BIDDER_ID) {
      slot.setTargeting('adunit', bid.divid);
      slot.setTargeting('_bd', 'bid');
      slot.setTargeting('_cp', bid.price);
      slot.setTargeting('_pl', bid.price_level);
      slot.setTargeting('_br', bid.bidder.reportid);
      slot.setTargeting('_wb', bid.id);
      slot.setTargeting('_sz', bid.size_code);
      slot.setTargeting('_bn', bid.bidder.name);
      slot.setTargeting('_bi', bid.bidder.bdrid);
      if (bid.dealid) {
        slot.setTargeting('_pdid', bid.dealid);
      }

      storedBids.set(slotElementId, bid);
    }
  });
}

const filterInvalidSizes = (advertSizes: AdUnitSizes): AdUnitSizes =>
  advertSizes.filter(size => includes(size, validSizes as AdUnitSizes));

const filterAndMapAdUnits = (
  filteredAdUnits: AdServerAdvert[],
  ad: DataObject<Ad>,
): AdServerAdvert[] => {
  const adMode = ad.getProperty('mode');
  const sizes: AdUnitSizes =
    adMode === AdUnitMode.OOP ? [[1, 1]] : filterInvalidSizes(ad.getProperty('sizes'));

  if (!sizes.length) {
    return filteredAdUnits;
  }

  const adID = ad.getProperty('id');
  const filteredAdvert: AdServerAdvert = {
    s: ad.getProperty('adUnitPath'),
    z: sizes,
    d: adID,
  };

  return filteredAdUnits.concat([filteredAdvert]);
};

const HYBRID_TEST_ID_TARGETING_NAME = 'hybridTestID';
const fetchBids = async (
  context: BordeauxMachineContext,
  adserverBidders: number[] | undefined,
  ads: Array<DataObject<Ad>>,
  callback: () => void,
): Promise<void> => {
  const { consent: uspConsent } = context.uspConsent;
  const permutiveSegments = await getPermutiveSegments();
  const gdprApplies = context.gdprConsent.consent ? context.gdprConsent.consent.gdprApplies : true;
  const gpdrConsentString = context.gdprConsent.consent?.tcString ?? null;
  const uspConsentString = uspConsent?.uspString ?? null;
  const gppConsentString = context.gppConsent.consent?.pingData.gppString ?? null;
  const gppConsentSID = ((arr: Array<number> | undefined): Array<number> | [] =>
    arr && arr.length === 1 && arr[0] === -1 ? [] : arr || [])(
    context.gppConsent.consent?.pingData.applicableSections,
  );

  const floorPrices = context.config.bidFloors.floorPrices ?? null;

  const filteredAdUnits: AdServerAdvert[] = ads.reduce(filterAndMapAdUnits, []);

  if (filteredAdUnits.length === 0) {
    callback();
    return;
  }

  generateRAMPRequest(context.thirdPartyApiConfig[ThirdParty.PUBMATIC])
    .setLocation(encodeURIComponent(env.location.toString().replace('%', '')))
    .setPersonalizedAdsMode(gdprApplies ? 'managed' : 'allowed')
    .setGdprConsent(gpdrConsentString)
    .setUSPConsent(uspConsentString)
    .setGPPConsent(gppConsentString)
    .setGPPSID(gppConsentSID)
    .setAdunits(filteredAdUnits)
    .setPageTemplate(context.pageTemplate ?? null)
    .setSegments(permutiveSegments ? permutiveSegments.toString() : '')
    .setFloorPrices(floorPrices)
    .setAdServerBidders(adserverBidders)
    .setHybridTestID(context.sommelierResponse.targeting?.[HYBRID_TEST_ID_TARGETING_NAME])
    .execute()
    .then(response => {
      processBids(response.bids);
      attachPageTargeting(response.targeting);
    })
    .catch(error => {
      log.error(`Error parsing AdServer bids and targeting: ${error}`);
    })
    .finally(() => {
      callback();
    });
};

export default (
  service: BordeauxMachineService,
  context: BordeauxMachineContext,
  adserverBidders: number[] | undefined,
  ads: Array<DataObject<Ad>>,
  timeout: number,
  id: number,
): Promise<string> => {
  service.send({
    type: REPORT_AUCTION_PARTNER.REQUEST,
    data: {
      time: timeData(),
      auction: id,
      partner: Partner.AD_SERVER,
    },
  });
  let resolveComplete: ((value: string) => void) | null;
  const complete = new Promise((resolve: (value: string) => void) => {
    resolveComplete = resolve;
  });

  setTimeout(() => {
    if (resolveComplete !== null) {
      resolveComplete('Timeout');
      resolveComplete = null;

      service.send({
        type: REPORT_AUCTION_PARTNER.TIMEOUT,
        data: {
          time: timeData(),
          auction: id,
          partner: Partner.AD_SERVER,
        },
      });
    }
  }, timeout);

  fetchBids(context, adserverBidders, ads, () => {
    if (resolveComplete !== null) {
      resolveComplete('Complete');
      resolveComplete = null;

      service.send({
        type: REPORT_AUCTION_PARTNER.SUCCESS,
        data: {
          time: timeData(),
          auction: id,
          partner: Partner.AD_SERVER,
        },
      });
    }
  });

  return complete;
};

initializeCreativeAdHandlerSafeframe(storedBids);
initializeCreativeAdViewHandlerSafeframe();
