import { Size } from '../../bordeaux.types';
import { SommelierResponse } from '../../sommelier/sommelier-response/index.types';

export enum CREATIVE_EVENT {
  RAMP_RENDER_REQUEST = 'RampRenderRequest',
  RAMP_RENDER_RESPONSE = 'RampRenderResponse',
  RAMP_AD_VIEW_EVENT = 'RampAdViewEvent',
}

export interface State {
  f: 1 | '';
  p: number | null;
  l: string | null;
  rf: string | null;
  fs: 0 | 1;
  t: string;
  tz: number;
  r: string | null;
  pam: string | null;
  gdprConsent: string | null;
  gppConsent: string | null;
  gppSID: Array<number> | null;
  ccpa: string | null;
  g: string[][];
  a: object[];
  ex: string[];
  identities?: Record<string, string>;
  tpl: string | null;
  seg: string | null;
  fp: Record<string, number> | null;
  ab?: Array<number>;
  htestid?: string[];
}

export interface ReceivedBidder {
  reportid: string;
  name: string;
  bdrid: string;
}

export interface TrackingParams {
  id: string;
  bid: string;
  cp: string;
  pl: string;
  z: string;
  bdrid: string;
  crid: string;
  ad: string;
  at: string;
  e: string;
  l: string;
  ts: string;
  chnl: string;
  dt: string;
  uid: string;
  div: string;
}

export interface Bid {
  id: string;
  divid: string;
  size: Size;
  size_code: string;
  cur: string;
  price: string;
  price_level: string;
  bidder: ReceivedBidder;
  type: string;
  adm: string;
  creativeid?: string;
  private_auction?: number;
  dealid?: string;
  tracking_params?: TrackingParams;
}

export interface BannerBid extends Bid {
  adm: string;
}

export interface RAMPResponse extends SommelierResponse {
  bids?: Array<Bid>;
}
