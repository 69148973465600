export enum DeviceOptions {
    TABLET = 'tablet',
    DESKTOP = 'desktop',
    MOBILE = 'mobile',
  }
  export enum BrowserOptions {
    OTHER = 'other',
    CHROME = 'chrome',
    FIREFOX = 'firefox',
    SAFARI = 'safari',
    IE = 'ie',
    EDGE = 'edge',
  }
  
  export interface PageParameters {
    browser: BrowserOptions;
    content: string;
    device: DeviceOptions;
    country: string;
    site: string;
    type: string;
  }
  