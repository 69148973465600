import { log } from '@repo/utils';
import { API_EVENTS_OUT } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';

export default (service: ApiMachineEventService, activationDistance: number): void => {
  if (typeof activationDistance !== 'number') {
    log.error(
      `Argument to setSlotActivationDistance must be a number, ${typeof activationDistance} given.`,
    );
    return;
  }

  service.sendEvent({
    type: API_EVENTS_OUT.SET_ACTIVATION_DISTANCE,
    data: activationDistance,
  });
};
