import DataObject from './data-object';

export default class DataObjectStore<DataType extends object> {
  private values: Array<DataObject<DataType>>;

  private subscribers: Array<(values: Array<DataObject<DataType>>) => void>;

  constructor() {
    this.values = [];
    this.subscribers = [];
  }

  public push(newData: DataObject<DataType>): void {
    this.values.push(newData);
    this.subscribers.forEach(subscriber => subscriber([...this.values]));
  }

  public getValues(): Array<DataObject<DataType>> {
    return this.values;
  }

  public subscribe(callback: (values: Array<DataObject<DataType>>) => void): void {
    this.subscribers.push(callback);
    callback([...this.values]);
  }
}
