import { log } from '@repo/utils';
import { API_EVENTS_OUT } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';

export default (service: ApiMachineEventService, refreshTime: number): void => {
  if (typeof refreshTime !== 'number') {
    log.error(`Argument to setRefreshTime must be a number, ${typeof refreshTime} given.`);
    return;
  }

  service.sendEvent({
    type: API_EVENTS_OUT.SET_REFRESH_TIME,
    data: refreshTime,
  });
};
