import { SiteConfig, ThirdParty } from './config.types';

export interface APISetupResult<TP = ThirdParty, TC = SiteConfig[keyof SiteConfig]> {
  thirdParty: TP;
  config: TC;
  consent: boolean;
  scriptLocation: string | undefined;
  success: boolean;
}

export interface FullAPISetupResults {
  [ThirdParty.AD_SERVER]: APISetupResult<ThirdParty.AD_SERVER, SiteConfig[ThirdParty.AD_SERVER]>;
  [ThirdParty.AMAZON]: APISetupResult<ThirdParty.AMAZON, SiteConfig[ThirdParty.AMAZON]>;
  [ThirdParty.AM_CLIO]: APISetupResult<ThirdParty.AM_CLIO, SiteConfig[ThirdParty.AM_CLIO]>;
  [ThirdParty.GPT]: APISetupResult<ThirdParty.GPT, SiteConfig[ThirdParty.GPT]>;
  [ThirdParty.IAS]: APISetupResult<ThirdParty.IAS, SiteConfig[ThirdParty.IAS]>;
  [ThirdParty.INDEX_EXCHANGE]: APISetupResult<
    ThirdParty.INDEX_EXCHANGE,
    SiteConfig[ThirdParty.INDEX_EXCHANGE]
  >;
  [ThirdParty.LIVE_RAMP]: APISetupResult<ThirdParty.LIVE_RAMP, SiteConfig[ThirdParty.LIVE_RAMP]>;
  [ThirdParty.PUBMATIC]: APISetupResult<ThirdParty.PUBMATIC, SiteConfig[ThirdParty.PUBMATIC]>;
  [ThirdParty.PREBID]: APISetupResult<ThirdParty.PREBID, SiteConfig[ThirdParty.PREBID]>;
}

export interface APISetupResultLegacy<TP = ThirdParty, TC = SiteConfig[keyof SiteConfig]> {
  name: TP;
  enabled: boolean;
  success: boolean;
  scriptLocation: string | undefined;
  config: TC;
}

export enum THIRD_PARTY_API_STATES {
  START = 'START',
  LOAD = 'LOAD',
  DONE = 'DONE',
}

export enum THIRD_PARTY_API_ACTIONS {
  GET_CONFIG = 'GET_CONFIG',
  GET_CONSENT = 'GET_CONSENT',
  GET_SCRIPT_LOCATION = 'GET_SCRIPT_LOCATION',

  MARK_SUCCESS = 'MARK_SUCCESS',

  SEND_REQUEST_EVENT = 'SEND_REQUEST_EVENT',
  SEND_SUCCESS_EVENT = 'SEND_SUCCESS_EVENT',
  SEND_FAILURE_EVENT = 'SEND_FAILURE_EVENT',
}
export enum THIRD_PARTY_API_GUARDS {
  NOT_ENABLED = 'NOT_ENABLED',
  NO_CONSENT = 'NO_CONSENT',
  NO_SCRIPT = 'NO_SCRIPT',
}
