import { DeviceOptions, DataObject, Ad } from '@repo/shared-types';
import { AdElementGroup } from '@repo/shared-types/src/types/ad-framework/ad/index.types';
import { createElement, getDeviceSize } from '@repo/utils';

const createAnchoredMarkup = (ad: DataObject<Ad>): AdElementGroup => {
  const isNotMobile = getDeviceSize() !== DeviceOptions.MOBILE;
  const hasCloseButton = isNotMobile || ad.getProperty('closeButton');

  const closeButton = hasCloseButton
    ? createElement(
        'div',
        {
          class: 'bordeaux-anchored-close',
          style: {
            position: 'absolute',
            width: '14px',
            height: '14px',
            top: '4px',
            right: '4px',
          },
          onClick: () => {
            if (outerContainer.parentNode) {
              outerContainer.parentNode.removeChild(outerContainer);
            }
          },
        },
        `
      <svg fill="rgb(178, 178, 178)" width="14" height="14" viewBox="341 8 14 14" xmlns="http://www.w3.org/2000/svg"><path fill="%234F4F4F" d="M354 9.31 352.69 8l-5.19 5.19L342.31 8 341 9.31l5.19 5.19-5.19 5.19 1.31 1.31 5.19-5.19 5.19 5.19 1.31-1.31-5.19-5.19z" fill-rule="evenodd"/></svg>
    `,
      )
    : undefined;

  const element = createElement('div', {
    id: ad.getProperty('id'),
    ariaHidden: 'true',
  });

  const innerContainer = createElement(
    'div',
    {
      class: 'bordeaux-anchored-inner mobile-leaderboard-320-50',
      style: {
        background: 'rgb(247, 247, 247)',
        display: 'block', // Block-level to ensure it respects height and width naturally
        height: 'auto', // Ensure the height adjusts to the content
      },
    },
    element,
  );

  const outerContainer = createElement(
    'div',
    {
      class: 'bordeaux-anchored-container',
      style: {
        display: 'block',
        position: 'fixed',
        bottom: '0',
        left: '0',
        width: '100%',
        height: 'auto', // Automatically adjusts to the content's height
        zIndex: '9995',
        textAlign: 'center', // Center the content horizontally
        background: 'rgb(247, 247, 247)',
      },
    },
    innerContainer,
    closeButton || null,
  );

  return {
    element,
    outerContainer,
    innerContainer,
    closeButton,
  };
};

export default createAnchoredMarkup;
