import { getGDPRData } from './utils';
import { UserSyncElement, UserSyncConfig } from '@repo/shared-types';

const oath: UserSyncConfig = {
  id: '57',
  bidderName: 'oath',
  iabIdList: [25],
  element: UserSyncElement.IFRAME,
  url: 'https://pixel.advertising.com/ups/58164/sync',
  params: {
    _origin: '1',
    redir: 'true',
  },
  dynamicParams: context => {
    const gdprData = getGDPRData(context.gdprConsent);
    return gdprData
      ? {
          gdpr: '1',
          gdpr_consent: gdprData,
        }
      : {
          gdpr: '0',
          gdpr_consent: '',
        };
  },
};
export default oath;
