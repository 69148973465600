import { z } from 'zod';
import { FeatureItem } from './index.types';
import { FALLBACK_HYBRID_FEATURES } from './fallback-gam-hybrid-features';

const customActivationFeature = z.object({
  LINE_ITEM: z.array(z.number()).optional(),
  ORDER: z.array(z.number()).optional(),
  CREATIVE: z.array(z.number()).optional(),
  ADVERTISER: z.array(z.number()).optional(),
});

const EMPTY_FEATURE_ITEM = {
  LINE_ITEM: [],
  ORDER: [],
  CREATIVE: [],
  ADVERTISER: [],
} as FeatureItem;
export const hybridFeaturesSchema = z
  .object({
    customActivations: z
      .object({
        AD_REFRESH_DISABLED: customActivationFeature.default(EMPTY_FEATURE_ITEM),
        VIDEO_STICKY_AUTOPLAY_DISABLED: customActivationFeature.default(EMPTY_FEATURE_ITEM),
        HIDEABLE_ANCHORED_ENABLED: customActivationFeature.default(EMPTY_FEATURE_ITEM),
      })
      .default(FALLBACK_HYBRID_FEATURES.customActivations),
  })
  .default({});
