import { BordeauxMachineContext, PAGE_STYLE_CONSTANTS } from '@repo/shared-types';
import { getEnv } from '@repo/utils';
import { fastdom } from '@repo/utils';
import { fromPromise } from 'xstate';

const readPageStyles = fromPromise<BordeauxMachineContext['pageStyleConstants']>(async () => {
  const env = getEnv();
  const constants: Record<PAGE_STYLE_CONSTANTS, () => string> = {
    [PAGE_STYLE_CONSTANTS.PAGE_BACKGROUND]: (): string =>
      env.getComputedStyle(document.body, null).getPropertyValue('background-color'),
    [PAGE_STYLE_CONSTANTS.PAGE_TEXT]: (): string =>
      env.getComputedStyle(document.body, null).getPropertyValue('color'),
  };
  return fastdom.measure(
    () =>
      Object.fromEntries(
        Object.entries(constants).map(([prop, getter]) => [prop, getter()]),
      ) as Record<PAGE_STYLE_CONSTANTS, string>,
  );
});
export default readPageStyles;
