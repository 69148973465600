import { removeAllSlotHighlights, removeSlotHighlight } from 'ad-framework/highlight/slots';

export default (names?: Array<string>): void => {
  if (names === undefined) {
    removeAllSlotHighlights();
    return;
  }

  names.forEach(name => {
    removeSlotHighlight(name);
  });
};
