import { getUserSyncURL } from './utils';
import { UserSyncElement, UserSyncConfig } from '@repo/shared-types';

const id = '59';
const _33Accross: UserSyncConfig = {
  element: UserSyncElement.IMAGE,
  id,
  bidderName: '33across',
  iabIdList: [58],
  url: 'https://ssc-cms.33across.com/ps/',
  params: {
    ri: '0013300001kQj3GAAS',
    ru: getUserSyncURL({ id, uid: '33XUSERID33X' }),
  },
};
export default _33Accross;
