import { createElement } from '@repo/utils';
import { Ad, DataObject } from '@repo/shared-types';
import { AdElementGroup } from '@repo/shared-types/src/types/ad-framework/ad/index.types';

const createSkyscraperMarkup = (ad: DataObject<Ad>): AdElementGroup => {
  const skysPos = ad.getProperty('position');

  const element = createElement('div', {
    id: ad.getProperty('id'),
    class: `skyscraper-gpt-${skysPos}`,
    ariaHidden: 'true',
    style: {
      width: '160px',
      height: '600px',
    },
  });

  const innerContainer = createElement(
    'div',
    {
      class: `skyscraper-inner-${skysPos}`,
      style: {
        width: '160px',
        height: '600px',
        pointerEvents: 'auto',
      },
    },
    element,
  );

  const outerContainer = createElement(
    'div',
    {
      class: `skyscraper-outer-${skysPos}`,
      style: {
        width: '160px',
        height: '600px',
        position: 'absolute',
        top: '10px',
        ...(skysPos ? { [skysPos]: `${-(180 + 20)}px` } : {}),
        zIndex: '9995',
      },
    },
    innerContainer,
  );

  return {
    element,
    innerContainer,
    outerContainer,
  };
};

export default createSkyscraperMarkup;
