import { BordeauxMachineContext, AnyBordeauxEvent } from '@repo/shared-types';
import {
  ActorRef,
  NonReducibleUnknown,
  sendTo as genericSendTo,
  Snapshot,
  ParameterizedObject,
} from 'xstate';

const sendTo = <
  M extends ActorRef<Snapshot<unknown>, any>,
  E extends AnyBordeauxEvent = AnyBordeauxEvent,
>(
  ...args: Parameters<
    typeof genericSendTo<
      BordeauxMachineContext,
      E,
      NonReducibleUnknown,
      M,
      AnyBordeauxEvent,
      string
    >
  >
) =>
  genericSendTo<BordeauxMachineContext, E, ParameterizedObject['params'], M, AnyBordeauxEvent>(
    ...args,
  );

export default sendTo;
