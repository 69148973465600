import { log } from '@repo/utils';
import processEUID from './euid';
import { getEnv, cookies, hasUserConsentedVendorGDPR } from '@repo/utils';
import createElements from './transformers/create-elements';
import {
  BordeauxMachineContext,
  UserSyncConfig,
  ThirdParty,
  GDPRConsent,
} from '@repo/shared-types';

const userSyncPixelHasConsent =
  (gdprConsent: GDPRConsent) =>
  (userSyncPixel: UserSyncConfig): boolean => {
    if (userSyncPixel.iabIdList.length > 0) {
      return userSyncPixel.iabIdList.some(iabId => hasUserConsentedVendorGDPR(gdprConsent, iabId));
    }
    return hasUserConsentedVendorGDPR(gdprConsent);
  };
export default (context: BordeauxMachineContext): void => {
  if (context.thirdPartyApiConfig[ThirdParty.AD_SERVER].enabled) {
    const cookiesMap = cookies.getAll();
    const unsyncedPixels = context.userSyncPixels.filter(
      (userSyncPixel: UserSyncConfig): boolean => {
        const cookieName = `usp.${userSyncPixel.id}`;
        return !cookiesMap[cookieName];
      },
    );
    unsyncedPixels.forEach(userSyncPixel => {
      const cookieName = `usp.${userSyncPixel.id}`;
      cookies.set(cookieName, '1', 604800);
      cookiesMap[cookieName] = '1';
    });

    const consentedPixels = unsyncedPixels.filter(userSyncPixelHasConsent(context.gdprConsent));
    const userSyncElements = ([] as Array<HTMLElement>).concat(
      ...consentedPixels.map(userSyncPixel => {
        try {
          return createElements(context, userSyncPixel);
        } catch (error) {
          log.error(error);
          return [];
        }
      }),
    );
    const env = getEnv();
    userSyncElements.forEach(element => {
      env.document.body.appendChild(element);
    });
  }
  processEUID(context.gdprConsent);
};
